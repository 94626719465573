import styled from "styled-components";
import { mediaMax, mediaMaxTablet } from "../../../helpers/MediaQueries";
import {Link} from "gatsby";

export const ContactBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 20px;
  width: 100%;
  align-items: center;
  ${mediaMaxTablet.phoneXL`
    padding-top: 0%;
    h1 {
      font-size: 30px;
      width: 100%;
      padding-left: 12%;
      line-height: 55px;
    }
  `}
`;
export const Logo = styled(Link)`
  max-width: 280px;
  width: 100%;
  margin: 88px auto 5%;
  display: block;
  ${mediaMax.phoneXL`
    max-width: 265px;
    display: flex;
    justify-content: center;
    margin: 0 auto 5%;
    padding-top: 48px;
  `};
`;
export const Contacts = styled.div`
  font-family: 'Proxima Nova', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
  margin-top: -5%;
  padding: 40px 0;
  b {
    font-weight: 400;
  }
  a {
    color: #000;
  }
  u {
    text-decoration: none;
  }
  ${mediaMax.phoneXL`
    padding: 40px 0 30px;
    span {
      padding-bottom: 14px;
      display: block;
    }
    b {
      display: none;
    }
  `}
`;
export const ContactFormWrapper = styled.div`
  min-height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContactSuccessHeader = styled.div`
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.44px;
  font-size: 30px;
  color: #222;
  text-transform: uppercase;
  ${mediaMax.phoneXL`
      font-size: 20px;
      padding: 0 20px;
      br {
        display: none;
      }
  `}
`;
