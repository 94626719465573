import styled from "styled-components";
import {mediaMax} from "../../../helpers/MediaQueries";

export const ContentWrap = styled.div`
  padding: 0.1% 8% 6% 8%;
  h1 {
    padding: 0 0 80px;
    font-weight: 100;
    ${mediaMax.tablet`
      font-size: 50px;
      padding: 50px 5.2% 20px;
      text-align: center;
    `};
  }
  ${mediaMax.tablet`
    padding-top: 0;
  `};
`;

export const Items = styled.div`
  display: flex;
  justify-content: center;
  max-width: 88%;
  margin: 0 auto;
  ${mediaMax.tablet`
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `};
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3%;
  margin: 0 auto;
  ${mediaMax.tablet`
    margin: 15% 0 0;
  `};

  &:first-child {
    h6 {
      padding-bottom: 25%;
    }
  }
  h6 {
    text-align: center;
    width: 100%;
    font-weight: 100;
    ${mediaMax.tablet`
      padding: 5% 0!important;
    `};
  }
  a {
    display: inline-block;
    svg {
      margin-left: 15px;
    }
  }
  img {
    padding-top: 15%;
    width: 358px;
    align-self: center;
    &.fact_sheet {
      width: 260px;
    }
  }
`;
