import * as React from "react";
import { seoData } from "../data/seo";
import { navData } from "../data/nav";
import Seo from "../components/seo";
import ContentNextPage from "../components/content-next-page";
import Footer from "../components/footer";
import ContentDownload from "../components/download/content-download";
import Composition from "../components/composition";
import useWindowSize from "../helpers/UseWindowSize";
import NewComposition from "../components/composition/test";
import {CompositionWrapper} from "../components/composition/test/styled.index";

const DownloadPage = () => {
  const {width} = useWindowSize();
  if (typeof window === `undefined`) {
    return(<></>);
  }
  return (
    <main>
      <Seo page={seoData.download} />
      <section>
        <ContentDownload />
        {width > 768 && <Composition imgName={"download-compos_1.png"} align={"bottom"} />}
      </section>
      <CompositionWrapper>
        <ContentNextPage
          type={"bg-full"}
          nextTitle={navData.contact.title}
          nextLink={navData.contact.link}
          vertAlign={'center'}
          mobHeight={"40vh"}
        />
        <NewComposition
          shape={"circle"}
          top={"0"}
          right={"93vw"}
          height={"600px"}
          mobBreakpoint={"phoneXL"}
          mobTop={"-10%"}
          mobRight={"90vw"}
          mobHeight={"100%"}
        />
        <NewComposition
          shape={"triangle"}
          top={"0"}
          right={"0"}
          height={"100%"}
          mobBreakpoint={"phoneXL"}
          mobTop={"auto"}
          mobBottom={"0"}
          mobRight={"0"}
          mobHeight={"80%"}
        />
      </CompositionWrapper>
      <Footer />
    </main>
  );
};

export default DownloadPage;
