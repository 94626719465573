import React, {FC, useContext, useEffect, useRef} from "react";
import { ContentWrap, Items, Item } from "./styled.index";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import img1 from "../../../assets/images/download/download_1.png";
import img2 from "../../../assets/images/download/download_2.png";
import brochurePDF from "../../../assets/static/RitzPompano_DigitalBrochure.pdf";
import factSheetPDF from "../../../assets/static/RitzPompano_DigitalFactSheet.pdf";
import {Logo} from "../../contact/contact-body/index.styled";
import IntroLogo from "../../../assets/images/intro_logo_dark.svg";
import {Context} from "../../../context";

const ContentDownload: FC = () => {
  const ContentWrapRef = useRef<HTMLDivElement>(null);
  const headingRef = useRef<HTMLHeadingElement>(null);
  const {setIsMenuLight} = useContext(Context)
  useEffect(() => {
    setIsMenuLight(false);
  }, [])
  useEffect(() => {
    ScrollTrigger.create({
      trigger: ContentWrapRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          headingRef?.current || null,
          0.75,
          { opacity: 0, y: 35 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          ContentWrapRef?.current || null,
          0.75,
          { opacity: 0, y: 35 },
          { opacity: 1, y: 0 },
          "+=.1"
        ),
    });
  }, []);
  return (
    <ContentWrap >
      <Logo to={'/'}><img src={IntroLogo} alt="" /></Logo>
      <h1 className={"heading fs-xxl"} ref={headingRef}>Download</h1>
      <Items ref={ContentWrapRef}>
        <Item>
          <a href={brochurePDF} target={"_blank"} rel="noopener noreferrer">
            <h6 className="fs-xl heading">
              Brochure
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="15.866"
                height="19.708"
                viewBox="0 0 15.866 19.708"
              >
                <defs>
                  <clipPath id="a">
                    <rect width="15.866" height="19.708" fill="#c9c3b8" />
                  </clipPath>
                </defs>
                <g clipPath="url(#a)">
                  <path
                    d="M10.624,11.633c.552-.4,1.061-.763,1.57-1.131.84-.608,1.675-1.222,2.519-1.823a1.068,1.068,0,1,1,1.235,1.743q-2.85,2.069-5.7,4.132a1.071,1.071,0,0,1-1.377,0q-2.854-2.063-5.7-4.132A1.069,1.069,0,1,1,4.4,8.682c1.293.928,2.58,1.865,3.87,2.8.058.042.119.079.217.144v-.8q0-4.869,0-9.738A1.067,1.067,0,0,1,10.6.845a1.786,1.786,0,0,1,.022.354q0,5.08,0,10.16v.274"
                    transform="translate(-1.623 0)"
                    fill="#c9c3b8"
                  />
                  <path
                    d="M7.934,44.372q3.434,0,6.869,0a1.064,1.064,0,0,1,.345,2.071,1.273,1.273,0,0,1-.392.06q-6.824,0-13.648,0a1.067,1.067,0,1,1,0-2.133q3.412,0,6.824,0"
                    transform="translate(0 -26.798)"
                    fill="#c9c3b8"
                  />
                </g>
              </svg>
            </h6>
            <img src={img1} alt={"The Ritz"} />
          </a>
        </Item>
        <Item>
          <a href={factSheetPDF} target={"_blank"} rel="noopener noreferrer">
            <h6 className="fs-xl heading">
              Fact sheet
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="15.866"
                height="19.708"
                viewBox="0 0 15.866 19.708"
              >
                <defs>
                  <clipPath id="a">
                    <rect width="15.866" height="19.708" fill="#c9c3b8" />
                  </clipPath>
                </defs>
                <g clipPath="url(#a)">
                  <path
                    d="M10.624,11.633c.552-.4,1.061-.763,1.57-1.131.84-.608,1.675-1.222,2.519-1.823a1.068,1.068,0,1,1,1.235,1.743q-2.85,2.069-5.7,4.132a1.071,1.071,0,0,1-1.377,0q-2.854-2.063-5.7-4.132A1.069,1.069,0,1,1,4.4,8.682c1.293.928,2.58,1.865,3.87,2.8.058.042.119.079.217.144v-.8q0-4.869,0-9.738A1.067,1.067,0,0,1,10.6.845a1.786,1.786,0,0,1,.022.354q0,5.08,0,10.16v.274"
                    transform="translate(-1.623 0)"
                    fill="#c9c3b8"
                  />
                  <path
                    d="M7.934,44.372q3.434,0,6.869,0a1.064,1.064,0,0,1,.345,2.071,1.273,1.273,0,0,1-.392.06q-6.824,0-13.648,0a1.067,1.067,0,1,1,0-2.133q3.412,0,6.824,0"
                    transform="translate(0 -26.798)"
                    fill="#c9c3b8"
                  />
                </g>
              </svg>
            </h6>
            <img src={img2} alt={"The Ritz"} className={'fact_sheet'}/>
          </a>
        </Item>
      </Items>
    </ContentWrap>
  );
};
export default ContentDownload;
